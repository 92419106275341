import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaTelegramPlane } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import { routes } from "../../../routes";

import { IModalData } from "../../../interfaces/modal.interfaces";

import styles from "./Modal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [data, setData] = useState<IModalData | null>(null);

  useEffect(() => {
    if (isOpen) {
      axios
        .get(routes.modalInfos)
        .then((response) => {
          setData(response.data.data[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [isOpen]);

  if (!isOpen || !data) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <IoMdClose onClick={onClose} className={styles.close} />
        <h2>{data.title && data.title}</h2>
        <p>{data.title && data.description}</p>
        <div className={styles.modalContent__button}>
          <a href={data.url} target="_blank" rel="noopener noreferrer">
            {data.buttonName && data.buttonName}
          </a>
          <FaTelegramPlane color="#fff" size={20} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
