import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { motion } from "framer-motion";

import { Loader } from "..";

import { routes } from "../../routes";

import { IHeroDataAttributes } from "../../interfaces/hero.interfaces";

import styles from "./HeroSection.module.scss";

import logo1 from "../../assets/images/logoIcon.svg";

const HeroSection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchHeroData = async (): Promise<IHeroDataAttributes | undefined> => {
    setIsLoading(true);
    try {
      const response = await axios.get(routes.heroSection);
      return response.data.data[0];
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const { data: heroData } = useQuery({
    queryKey: ["heroData"],
    queryFn: fetchHeroData,
    initialData: null,
    cacheTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <Loader />;

  if (!heroData) return <div></div>;

  const {
    title,
    description,
    date,
    isButton,
    buttontText,
    bittonLink,
    logo,
    memberImage,
    bgImage,
  } = heroData;

  const handleScroll = (targetId: string | null) => {
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={styles.hero}>
      {bgImage && (
        <img
          src={bgImage?.url}
          alt="Background"
          className={styles.hero__bgImage}
        />
      )}

      <div className={styles.hero__content}>
        <div className={styles.hero__content__leftBlock}>
          {logo && (
            <img
              className={styles.hero__content__leftBlock__logo}
              src={logo?.url}
              alt="Logo"
            />
          )}
          <h2>{title && title}</h2>
          <h4>{description && description}</h4>
          <span>{date && date}</span>
          {isButton && (
            <button
              onClick={() => {
                if (bittonLink) {
                  if (
                    bittonLink.startsWith("http://") ||
                    bittonLink.startsWith("https://")
                  ) {
                    window.open(bittonLink, "_blank", "noopener,noreferrer");
                  } else {
                    handleScroll(bittonLink);
                  }
                }
              }}
            >
              <img src={logo1} alt="logo" />
              {buttontText}
            </button>
          )}
        </div>

        {memberImage && (
          <motion.div
            initial={{ opacity: 0, x: 300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
            transition={{ duration: 0.8 }}
            className={styles.hero__content__rightBlock}
          >
            {memberImage && <img src={memberImage?.url} alt="Member" />}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default HeroSection;
