import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { routes } from "../../routes";

import { IBlockData, IBlockTitle } from "../../interfaces/block.interfaces";

import styles from "./BlocksSection.module.scss";

import logoIcon from "../../assets/images/logoIcon.svg";

const BlocksSection = () => {
  const fetchBlockData = async (): Promise<IBlockData[]> => {
    const response = await axios.get(routes.blockSecion);
    if (response.data && response.data.data.length > 0) {
      return response.data.data;
    }
    throw new Error("No data found");
  };

  const fetchBlockTitle = async (): Promise<IBlockTitle> => {
    const response = await axios.get(routes.blockSectionTitle);
    if (response.data && response.data.data.length > 0) {
      return response.data.data[0];
    }
    throw new Error("No data found");
  };

  const { data: blockTitle } = useQuery({
    queryKey: ["blockTitle"],
    queryFn: fetchBlockTitle,
    initialData: null,
    staleTime: 1,
    cacheTime: 1000 * 60 * 10,
  });

  const { data: blockData } = useQuery({
    queryKey: ["blockData"],
    queryFn: fetchBlockData,
    initialData: null,
    staleTime: 1,
    cacheTime: 1000 * 60 * 10,
  });

  const parseTitleToHTML = (title: any): string => {
    return title
      .map((block: any) =>
        block.children.map((child: any) => child.text || "").join("")
      )
      .join("<br/>");
  };

  const handleScroll = (targetId: string | null) => {
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={styles.blocks}>
      <div className={styles.blocks__content}>
        <h2>{blockTitle && blockTitle.title}</h2>
        <div className={styles.blocks__content__block}>
          {blockData &&
            blockData.map((block: any) => (
              <div
                key={block.id}
                className={styles.blocks__content__block__item}
              >
                <h4
                  dangerouslySetInnerHTML={{
                    __html: parseTitleToHTML(block.title),
                  }}
                />
                {block.img && (
                  <img src={block.img[0].url} alt={`icon-${block.id}`} />
                )}
              </div>
            ))}
        </div>
        {blockTitle && blockTitle.isButton && (
          <button
            onClick={() => {
              if (blockTitle.buttonLink) {
                if (
                  blockTitle.buttonLink.startsWith("http://") ||
                  blockTitle.buttonLink.startsWith("https://")
                ) {
                  window.open(
                    blockTitle.buttonLink,
                    "_blank",
                    "noopener,noreferrer"
                  );
                } else {
                  handleScroll(blockTitle.buttonLink);
                }
              }
            }}
          >
            <img src={logoIcon} alt="logo" />
            {blockTitle.buttontText}
          </button>
        )}
      </div>
    </div>
  );
};

export default BlocksSection;
