import { useEffect, useState } from "react";

import axios from "axios";

import { routes } from "../../routes";

import {
  ISocialLink,
  ISpeakerData,
  ITitle,
} from "../../interfaces/speaker.interfaces";

import styles from "./AboutSpeakerSection.module.scss";

const AboutSpeakerSection = () => {
  const [speakerData, setSpeakerData] = useState<ISpeakerData | null>(null);
  const [socialLinks, setSocialLinks] = useState<ISocialLink[]>([]);
  const [title, setTitle] = useState<ITitle[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(routes.aboutSpeaker);
        setSpeakerData(response.data.data[0]);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchDataSocials = async () => {
      try {
        const response = await axios.get(routes.socialsIcons);
        const socialLinksData = await Promise.all(
          response.data.data.map(async (link: any) => {
            const svgResponse = await axios.get(link.icon[0]?.url);
            return {
              ...link,
              icon: [{ ...link.icon[0], svgContent: svgResponse.data }],
            };
          })
        );
        setSocialLinks(socialLinksData);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchDataTitle = async () => {
      try {
        const response = await axios.get(routes.aboutSpeakerTitle);
        setTitle(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
    fetchDataSocials();
    fetchDataTitle();
  }, []);

  if (!speakerData) {
    return <div></div>;
  }


  return (
    <div className={styles.aboutMe}>
      <h2> {title && title[0]?.title}</h2>
      <div className={styles.aboutMe__content}>
        <div className={styles.aboutMe__content__right}>
          {speakerData && (
            <img
              src={speakerData?.speakerImg?.url}
              className={styles.aboutMe__content__right__img}
              alt={speakerData.speakerImg?.alternativeText || "Speaker Image"}
            />
          )}
        </div>
        <div className={styles.aboutMe__content__left}>
          <h3>{speakerData.title && speakerData.title}</h3>
          <span>{speakerData.desc1 && speakerData.desc1}</span>
          <h4>{speakerData.desc2 && speakerData.desc2}</h4>
          <p>{speakerData.desc3 && speakerData.desc3}</p>
          <div className={styles.aboutMe__content__left__links}>
            {speakerData.isSocial &&
              socialLinks &&
              socialLinks.map((link) => (
                <a
                  key={link.id}
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className={styles.aboutMe__content__left__links__link}
                    dangerouslySetInnerHTML={{
                      //@ts-ignore
                      __html: link.icon[0]?.svgContent,
                    }}
                  />
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSpeakerSection;
