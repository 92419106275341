import React, { useEffect, useState } from "react";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { marked } from "marked";

import "react-phone-number-input/style.css";

import { Modal } from "..";

import { routes } from "../../../routes";

import styles from "./Form.module.scss";

import logoIcon from "../../../assets/images/logoIcon.svg";

const Form = () => {
  const [formData, setFormData] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(routes.formData);
        setFormData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchFormData();
  }, []);

  const handleInputChange = (field: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setFormErrors({});
    let isValid = true;

    if (!formValues["firstField"]?.trim()) {
      setFormErrors((prev) => ({
        ...prev,
        firstField: "Заповніть поле.",
      }));
      isValid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (
      !formValues["secondField"] ||
      !emailRegex.test(formValues["secondField"])
    ) {
      setFormErrors((prev) => ({
        ...prev,
        secondField: "Заповніть поле.",
      }));
      isValid = false;
    }

    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      setPhoneError("Будь ласка, введіть правильний номер телефону.");
      isValid = false;
    } else {
      setPhoneError(null);
    }

    if (!formValues["fourthField"]?.trim()) {
      setFormErrors((prev) => ({
        ...prev,
        fourthField: "Заповніть поле.",
      }));
      isValid = false;
    }

    if (!isValid) return;

    const formDataForBot = {
      full_name: formValues["firstField"],
      email: formValues["secondField"],
      phone_number: phoneNumber,
      type_of_activity: formValues["fourthField"],
    };

    const formDataForCMS = {
      name: formValues["firstField"],
      email: formValues["secondField"],
      phoneNumber,
      typeOfActivity: formValues["fourthField"],
    };

    try {
      setIsLoading(true);
      setError(null);

      await axios.post("https://landingcms.onrender.com/api/forms", {
        data: formDataForCMS,
      });

      await axios.post(
        "https://landingbot.onrender.com/bot/send_to_channel",
        formDataForBot
      );

      await axios.post("https://landingbot.onrender.com/bot/send_excel");

      setIsModalOpen(true);
      setFormValues({});
      setPhoneNumber("");
    } catch (err) {
      setError("Помилка при надсиланні запиту. Спробуйте ще раз.");
      console.error("Error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  let htmlText;

  if (formData[0]?.bottomText) {
    const markdownText = formData[0]?.bottomText;
    htmlText = marked(markdownText);

    //@ts-ignore
    htmlText = htmlText.replace(
      /<a /g,
      '<a target="_blank" rel="noopener noreferrer" '
    );
  }

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit} id="form">
        <p>{formData[0]?.title}</p>

        {formData.length > 0 && (
          <>
            {formData[0]?.firstField && (
              <div>
                <input
                  type="text"
                  placeholder={formData[0].firstField || "Ім`я та прізвище"}
                  value={formValues["firstField"] || ""}
                  onChange={(e) =>
                    handleInputChange("firstField", e.target.value)
                  }
                  className={formErrors["firstField"] ? styles.errorInput : ""}
                />
                {formErrors["firstField"] && (
                  <p className={styles.errorMessage}>
                    {formErrors["firstField"]}
                  </p>
                )}
              </div>
            )}

            {formData[0]?.secondField && (
              <div>
                <input
                  type="email"
                  placeholder={formData[0].secondField || "E-mail"}
                  value={formValues["secondField"] || ""}
                  onChange={(e) =>
                    handleInputChange("secondField", e.target.value)
                  }
                  className={formErrors["secondField"] ? styles.errorInput : ""}
                />
                {formErrors["secondField"] && (
                  <p className={styles.errorMessage}>
                    {formErrors["secondField"]}
                  </p>
                )}
              </div>
            )}

            {formData[0]?.thirdField && (
              <div>
                <div
                  className={
                    phoneError ? styles.errorInput : styles.phoneInputContainer
                  }
                >
                  <div className={styles.phoneInputWrapper}>
                    <PhoneInput
                      international
                      defaultCountry="UA"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      placeholder={formData[0].thirdField || "Номер телефону"}
                      className={`${styles.phoneInput} ${
                        phoneError ? styles.errorInput : ""
                      }`}
                      limitMaxLength={true}
                    />
                  </div>
                </div>
                {phoneError && (
                  <p className={styles.errorMessage}>{phoneError}</p>
                )}
              </div>
            )}

            {formData[0]?.fourthField && (
              <div>
                <input
                  type="text"
                  placeholder={formData[0].fourthField || "Рід діяльності"}
                  value={formValues["fourthField"] || ""}
                  onChange={(e) =>
                    handleInputChange("fourthField", e.target.value)
                  }
                  className={formErrors["fourthField"] ? styles.errorInput : ""}
                />
                {formErrors["fourthField"] && (
                  <p className={styles.errorMessage}>
                    {formErrors["fourthField"]}
                  </p>
                )}
              </div>
            )}
          </>
        )}

        <button type="submit" disabled={isLoading}>
          {isLoading ? (
            <>
              <img
                src={logoIcon}
                alt="logoIcon"
                className={styles.loadingIcon}
              />
              "Надсилання..."
            </>
          ) : (
            <>
              <img src={logoIcon} alt="logoIcon" />
              {(formData[0]?.buttonName && formData[0]?.buttonName) ||
                "Надіслати запит"}
            </>
          )}
        </button>
        {error && <p className={styles.errorMessage}>{error}</p>}

        <div
          className={styles.disclaimer}
          //@ts-ignore

          dangerouslySetInnerHTML={{ __html: htmlText }}
        />
      </form>

      <Modal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  );
};

export default Form;
