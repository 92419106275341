import { useEffect, useState } from "react";
import axios from "axios";

import { routes } from "../../routes";
import {
  ISecondBlockData,
  ISecondBlockTitle,
} from "../../interfaces/block.interfaces";

import styles from "./SecondBlocksSection.module.scss";

import logoIcon from "../../assets/images/logoIcon.svg";

const SecondBlocksSection = () => {
  const [webinarData, setWebinarData] = useState<ISecondBlockData[] | null>(
    null
  );
  const [blockTitle, setBlockTitle] = useState<ISecondBlockTitle | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(routes.secondBlockSection);
        setWebinarData(response.data.data);
      } catch (err) {
        console.log("Error loading webinar data.", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(routes.secondBlockSectionTitle);
        setBlockTitle(response.data.data[0]);
      } catch (err) {
        console.log("Error loading webinar title.", err);
      }
    };

    fetchData();
  }, []);

  if (!webinarData || !blockTitle) {
    return <div></div>;
  }

  const parseTitleToHTML = (text: any): string => {
    return text
      .map((block: any) =>
        block.children.map((child: any) => child.text || "").join("")
      )
      .join("<br/>");
  };
  const handleScroll = (targetId: string | null) => {
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className={styles.secondBlocks}>
      <h2>{blockTitle && blockTitle.title}</h2>

      <div className={styles.secondBlocks__content}>
        {webinarData &&
          webinarData.map((block: any) => (
            <div
              key={block.id}
              className={
                block.image
                  ? `${styles.blocks__content__block} ${styles.blocks__content__blockWithImage}`
                  : styles.blocks__content__block
              }
            >
              <h3
                dangerouslySetInnerHTML={{
                  __html: parseTitleToHTML(block.text),
                }}
              />
              {block.isButton && (
                <a href={block.buttonLink} className={styles.button}>
                  {block.buttontText}
                </a>
              )}
              {block.image && <img src={block.image.url} alt="icon" />}
            </div>
          ))}
      </div>

      {blockTitle.isButton && (
        <button
          onClick={() => {
            if (blockTitle.buttonLink) {
              if (
                blockTitle.buttonLink.startsWith("http://") ||
                blockTitle.buttonLink.startsWith("https://")
              ) {
                window.open(
                  blockTitle.buttonLink,
                  "_blank",
                  "noopener,noreferrer"
                );
              } else {
                handleScroll(blockTitle.buttonLink);
              }
            }
          }}
        >
          <img src={logoIcon} alt="logo" />
          {blockTitle.buttontText}
        </button>
      )}
    </div>
  );
};

export default SecondBlocksSection;
