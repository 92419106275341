const BASE_URL = process.env.REACT_APP_BASE_URL;

export const routes = {
  aboutSpeakerTitle: `${BASE_URL}api/blok4-zagolovoks`,
  aboutSpeaker: `${BASE_URL}api/about-speakers?populate=speakerImg`,
  socialsIcons: `${BASE_URL}api/socials?populate=icon`,
  blockSectionTitle: `${BASE_URL}api/blok2-zagolovoks`,
  blockSecion: `${BASE_URL}api/second-screens?populate=img&sort=createdAt`,

  formTilte: `${BASE_URL}api/blok5-zagolovoks`,
  formData: `${BASE_URL}api/formas`,
  formBlock1: `${BASE_URL}api/blok5-pidblok1s`,
  formBlock2: `${BASE_URL}api/blok5-pidblok2s`,
  formBlock3: `${BASE_URL}api/blok5-pidblok3s`,

  dataForCalendar: `${BASE_URL}api/google-calendars`,
  secondBlockSectionTitle: `${BASE_URL}api/blok3-zagolovoks`,
  secondBlockSection: `${BASE_URL}api/third-screens?populate=image&sort=createdAt`,

  heroSection: `${BASE_URL}api/home-screeens?populate=logo&populate=memberImage&populate=bgImage`,

  date: `${BASE_URL}api/calendar-dates`,
  modalInfos: `${BASE_URL}api/success-modal-infos`,

  seoSettings: `${BASE_URL}api/seo-nalashtuvannyas?populate=img`,
};
