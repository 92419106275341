import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IoTimeOutline } from "react-icons/io5";
import { IoMdArrowUp } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";

import { Form } from "..";

import { calculateTimeRemaining } from "../../utils/timeRemaining";
import { formatTime } from "../../helpers/time";

import { routes } from "../../routes";

import { IDataItem } from "../../interfaces/block.interfaces";
import { IFormattedDate } from "../../interfaces/date.interfaces";

import styles from "./FormSection.module.scss";

const FormSection = () => {
  const targetDate = useRef<Date | null>(null);
  const [dataTitle, setDataTitle] = useState<IDataItem[]>([]);
  const [dataBlcok1, setDataBlock1] = useState<IDataItem[]>([]);
  const [dataBlcok2, setDataBlock2] = useState<IDataItem[]>([]);
  const [dataBlcok3, setDataBlock3] = useState<IDataItem[]>([]);
  const [dataForCalendar, setDataForCalendar] = useState<any[]>([]);
  const [isVisible, setIsVisible] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(targetDate.current || new Date())
  );

  const getGenitiveMonth = (month: string) => {
    const months = {
      січень: "січня",
      лютий: "лютого",
      березень: "березня",
      квітень: "квітня",
      травень: "травня",
      червень: "червня",
      липень: "липня",
      серпень: "серпня",
      вересень: "вересня",
      жовтень: "жовтня",
      листопад: "листопада",
      грудень: "грудня",
    };

    //@ts-ignore
    return months[month.toLowerCase()] || month;
  };

  const [formattedDate, setFormattedDate] = useState<IFormattedDate>({
    monthLong: "",
    monthShort: "",
    date: "",
    dayOfWeekLong: "",
    dayOfWeekShort: "",
  });
  const getFormattedDate = (date: number) => {
    return date.toString().padStart(2, "0");
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAddToCalendar = () => {
    if (targetDate.current) {
      const eventTitle = dataForCalendar ? dataForCalendar[0].title : "Title";
      const eventDescription = dataForCalendar
        ? dataForCalendar[0].description
        : "Реєстрація на вебінар.";

      const startDate = new Date(targetDate.current);

      const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

      const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}${month}${day}T${hours}${minutes}${seconds}`;
      };

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        eventTitle
      )}&details=${encodeURIComponent(
        eventDescription
      )}&dates=${formattedStartDate}/${formattedEndDate}`;

      window.open(googleCalendarUrl, "_blank");
    }
  };

  useEffect(() => {
    const fetchDate = async () => {
      try {
        const response = await axios.get(routes.date);
        const apiDate = response.data.data[0].time;
        targetDate.current = new Date(apiDate);

        const monthFormatterLong = new Intl.DateTimeFormat("uk-UA", {
          month: "long",
        });
        const monthFormatterShort = new Intl.DateTimeFormat("uk-UA", {
          month: "short",
        });
        const dayOfWeekFormatterLong = new Intl.DateTimeFormat("uk-UA", {
          weekday: "long",
        });
        const dayOfWeekFormatterShort = new Intl.DateTimeFormat("uk-UA", {
          weekday: "short",
        });

        setFormattedDate({
          monthLong: monthFormatterLong.format(targetDate.current),
          monthShort: monthFormatterShort.format(targetDate.current),
          date: targetDate.current.getDate().toString(),
          dayOfWeekLong: dayOfWeekFormatterLong.format(targetDate.current),
          dayOfWeekShort: dayOfWeekFormatterShort.format(targetDate.current),
        });

        setTimeRemaining(calculateTimeRemaining(targetDate.current));
      } catch (error) {
        console.error("Error fetching the date:", error);
      }
    };

    fetchDate();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (targetDate.current) {
        const newTimeRemaining = calculateTimeRemaining(targetDate.current);
        setTimeRemaining(newTimeRemaining);

        if (
          newTimeRemaining.days === 0 &&
          newTimeRemaining.hours === 0 &&
          newTimeRemaining.minutes === 0 &&
          newTimeRemaining.seconds === 0
        ) {
          clearInterval(intervalId);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchDataTitle = async () => {
      try {
        const response = await axios.get(routes.formTilte);
        setDataTitle(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataTitle();
  }, []);

  useEffect(() => {
    const fetchDataForCalendar = async () => {
      try {
        const response = await axios.get(routes.dataForCalendar);
        setDataForCalendar(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataForCalendar();
  }, []);

  useEffect(() => {
    const fetchDataBlock1 = async () => {
      try {
        const response = await axios.get(routes.formBlock1);
        setDataBlock1(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataBlock1();
  }, []);

  useEffect(() => {
    const fetchDataBlock2 = async () => {
      try {
        const response = await axios.get(routes.formBlock2);
        setDataBlock2(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataBlock2();
  }, []);

  useEffect(() => {
    const fetchDataBlock3 = async () => {
      try {
        const response = await axios.get(routes.formBlock3);
        setDataBlock3(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataBlock3();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 300;
      if (window.scrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formattedTime = formatTime(targetDate && targetDate.current);

  return (
    <div className={styles.formSection}>
      <div className={styles.formSection__content__left}>
        <h2>{dataTitle && dataTitle[0]?.title}</h2>

        {dataBlcok1 &&
          dataBlcok1.map((dataItem, index) => (
            <div
              key={index}
              className={styles.formSection__content__left__item}
            >
              <h4>{dataItem.title && dataItem.title}</h4>
              {dataItem &&
                Object.keys(dataItem).map(
                  (key) =>
                    key.startsWith("item") &&
                    dataItem[key as keyof IDataItem] && (
                      <p
                        key={key}
                        className={styles.formSection__content__left__text}
                      >
                        <a
                          className={
                            styles.formSection__content__left__text__link
                          }
                          href={`mailto:${dataItem[key as keyof IDataItem]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {dataItem[key as keyof IDataItem]}
                        </a>
                      </p>
                    )
                )}
            </div>
          ))}

        <div className={styles.formSection__content__left__links}>
          {dataBlcok2.map((dataItem, index) => (
            <div key={index}>
              <h4>{dataItem.title && dataItem.title}</h4>
              <div
                key={index}
                className={styles.formSection__content__left__link}
              >
                {[
                  { Name: dataItem.item1, link: dataItem.item1Link },
                  { Name: dataItem.item2, link: dataItem.item2Link },
                  { Name: dataItem.item3, link: dataItem.item3Link },
                  { Name: dataItem.item4, link: dataItem.item4Link },
                  { Name: dataItem.item5, link: dataItem.item5Link },
                ]
                  .filter((item) => item.Name && item.link)
                  .map((link, index) => (
                    <a
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.link}
                    >
                      {link.Name}
                    </a>
                  ))}
              </div>
            </div>
          ))}
        </div>
        {dataBlcok3 &&
          dataBlcok3.map((dataItem, index) => (
            <div key={index}>
              <h4>{dataItem.title && dataItem.title}</h4>
              {Object.keys(dataItem).map(
                (key) =>
                  key.startsWith("item") &&
                  dataItem[key as keyof IDataItem] && (
                    <p key={key}>{dataItem[key as keyof IDataItem]}</p>
                  )
              )}
            </div>
          ))}
      </div>

      <div className={styles.formSection__content__right}>
        <div className={styles.formSection__content__right__calendarblock}>
          <div className={styles.calendar}>
            <div className={styles.month}>
              {getGenitiveMonth(formattedDate.monthLong)}
            </div>
            <div className={styles.date}>{formattedDate.date}</div>
            <div className={styles.dayOfWeek}>
              {formattedDate.dayOfWeekShort.toUpperCase()}
            </div>
          </div>
          <div className={styles.formSection__content__right__eventBlock}>
            <span>{formattedDate.dayOfWeekLong}</span>
            <h3>
              {`${formattedDate.date} ${getGenitiveMonth(
                formattedDate.monthLong
              )}`}
            </h3>
            <div>
              <IoTimeOutline />
              <span>{formattedTime}</span>
            </div>
            <button onClick={handleAddToCalendar}>Додати до календаря</button>
          </div>
        </div>
        <div className={styles.formSection__content__right__timer}>
          <div>
            <div>{getFormattedDate(timeRemaining.days)}</div>

            <span>днів</span>
          </div>
          <div>
            <div>{getFormattedDate(timeRemaining.hours)}</div>

            <span>годин</span>
          </div>
          <div>
            <div>{getFormattedDate(timeRemaining.minutes)}</div>

            <span>хвилин</span>
          </div>
          <div>
            <div>{getFormattedDate(timeRemaining.seconds)}</div>

            <span>секунд</span>
          </div>
        </div>
        <Form />
      </div>

      <AnimatePresence>
        {isVisible && (
          <motion.div
            className={styles.formSection__arrow}
            onClick={handleScrollToTop}
            style={{ cursor: "pointer" }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            <IoMdArrowUp color="#fff" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FormSection;
