import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./index.scss";
import axios from "axios";
import Cookies from "js-cookie";

import {
  AboutSpeakerSection,
  BlocksSection,
  FormSection,
  HeroSection,
  SecondBlocksSection,
} from "./components";

import { routes } from "./routes";

const App = () => {
  const [pageData, setPageData] = useState<any>(null);
  const [showCookieModal, setShowCookieModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(routes.seoSettings);
        setPageData(response.data.data);
      } catch (err) {
        console.error("Error fetching page data:", err);
      }
    };

    fetchData();

    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setShowCookieModal(true);
    }
  }, []);

  const handleCookieConsent = () => {
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setShowCookieModal(false);
  };

  const handleCookieReject = () => {
    Cookies.set("cookieConsent", "false", { expires: 365 });
    setShowCookieModal(false);
  };

  const pageDescription =
    (pageData && pageData?.[0]?.description) || "Default Description";
  const pageKeywords =
    (pageData && pageData?.[0]?.keywords) || "Default Keywords";
  const pageTitle = (pageData && pageData?.[0]?.title) || "Default Title";
  const pageImageUrl =
    (pageData && pageData?.[0]?.img.url) || "https://pro.zeminvest.ua/";
  const pageUrl = "https://pro.zeminvest.ua";

  return (
    <>
      {pageData && (
        <Helmet>
          <title>
            {pageTitle || "Як почати інвестувати в землю в 2025 році"}
          </title>
          <meta
            property="og:title"
            content={pageTitle || "Як почати інвестувати в землю в 2025 році"}
          />
          <meta
            name="description"
            content={pageDescription || "Default description"}
          />
          {/* <meta name="keywords" content={pageKeywords || "default, keywords"} /> */}

          <meta
            property="og:description"
            content={pageDescription || "Default description"}
          />
          <meta
            property="og:image"
            content={
              pageImageUrl ||
              "https://res.cloudinary.com/djvrwucfh/image/upload/v1731724506/seo_test_c5df324251.jpg"
            }
          />
          <meta
            property="og:url"
            content={pageUrl || "https://pro.zeminvest.ua"}
          />
          <meta property="og:type" content="website" />

          <meta
            name="twitter:title"
            content={pageTitle || "Як почати інвестувати в землю в 2025 році"}
          />
          <meta
            name="twitter:description"
            content={pageDescription || "Default description"}
          />
          <meta
            name="twitter:image"
            content={
              pageImageUrl ||
              "https://res.cloudinary.com/djvrwucfh/image/upload/v1731724506/seo_test_c5df324251.jpg"
            }
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content={pageTitle || "Вебінар"} />
        </Helmet>
      )}

      <HeroSection />
      <BlocksSection />
      <SecondBlocksSection />
      <AboutSpeakerSection />
      <FormSection />

      {/* {showCookieModal && (
        <div className={"cookieModal"}>
          <p>
            <span> Ми цінуємо вашу конфіденційність. </span>
            <br />
            Ми використовуємо кукіс для покращення вашого досвіду перегляду,
            надання персоналізованих оголошень чи контенту та аналізу нашого
            трафіку. Натискаючи "Прийняти всі", ви погоджуєтесь на використання
            кукісів.
          </p>
          <div className="cookieButtons">
            <button onClick={handleCookieConsent} className={"cookieButton"}>
              Я погоджуюсь
            </button>
            <button
              onClick={handleCookieReject}
              className={"cookieButtonReject"}
            >
              Відхилити
            </button>
          </div>
        </div>
      )} */}
    </>
  );
};

export default App;
